/*-----------------------------------------------
|   Dashboard
-----------------------------------------------*/
.file-thumbnail {
  height: 2.25rem;
  width: 2.25rem;
}

.dot {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  display: inline-block;
  margin-right: map_get($spacers, 2);
}

.small-dot {
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  display: inline-block;
  margin-left: map_get($spacers, 2);
}

/*-----------------------------------------------
|             E-commerce
-----------------------------------------------*/
.greetings-item {
  background-color: $white !important;

  html[data-bs-theme="dark"] & {
    background-color: $card-bg-dark !important;
  }
}

/*-----------------------------------------------
|             Project Management
-----------------------------------------------*/
.recent-activity-body-height {
  height: 24rem;
}

.table-member-info {
  min-width: 43rem;
}

.members-activity {
  height: 22rem;
}

.table-running-project {
  min-width: 43rem;
}

/*-----------------------------------------------
|             support tickets
-----------------------------------------------*/

// .table-view-tickets {
//   min-width: 55rem;
// }

.ticket-todo-list {
  height: 25.65rem;
  .preview-todo-list & {
    height: 24rem;
  }
}

.hover-actions-trigger.btn-reveal-trigger {
  .hover-actions {
    right: 3.5rem;

    [dir="rtl"] & {
      right: 3.5rem;
    }
  }
}

/* -------------------------------------------------------------------------- */
/*                                  Analytics                                 */
/* -------------------------------------------------------------------------- */
.ask-analytics {
  @include media-breakpoint-up(md) {
    max-height: 26.625rem;
  }

  .ask-analytics-item {
    @include hover-focus {
      background: var(--#{$prefix}gray-100);
    }
  }
}

/* -------------------------------------------------------------------------- */
/*                                  CRM                                       */
/* -------------------------------------------------------------------------- */
.table-recent-leads {
  min-width: 38rem;
}

.tab-active-caret {
  .nav-link {
    color: var(--#{$prefix}gray-600) !important;
    font-size: map-get($font-sizes, 10) !important;
    font-weight: $font-weight-medium !important;
    font-family: var(--#{$prefix}font-sans-serif);

    &.active {
      color: var(--#{$prefix}primary) !important;
      border-bottom: 0 !important;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        bottom: -6px;
        left: 45%;
        transform: rotate(45deg);
        background: var(--#{$prefix}emphasis-bg);
        border-radius: 0.125rem;
        border-width: 0 1px 1px 0;
        border-style: solid;
        border-color: $dropdown-border-color;
      }
    }

  }
}

// Used in crm deal forecast card
html[dir="rtl"] .rtl-row-reverse {
  flex-direction: row-reverse;
}

/* -------------------------------------------------------------------------- */
/*                                  LMS                                       */
/* -------------------------------------------------------------------------- */
.bar-indicator-gradient {
  background: linear-gradient(to right, rgba($primary, 0.1), var(--#{$prefix}primary));
  height: 0.25rem;
  width: 100%;
  border-radius: 1rem;
}

.echart-avg-enrollment-rate {
  min-height: 18.75rem;
}

.d3-trending-keywords {
  height: 25rem;
}

.lms-half-doughnut {
  max-width: 10.625rem;
}

.echart-marketing-expenses {
  min-height: 18.75rem;
}

.marketing-exp-circle {
  width: 6.25rem;
  height: 6.25rem;
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.075);
}

.echart-weekly-goals-lms {
  height: 15.625rem;
}

.echart-bar-course-enrollments {
  min-height: 15.625rem;
}

.top-courses-tab {
  .nav-link {
    color: var(--falcon-gray-400) !important;

    &:not(.active):hover {
      border-color: transparent !important;
    }

    &.active {
      border-bottom: 2px solid var(--#{$prefix}primary);
      background-color: var(--#{$prefix}emphasis-bg);
      color: var(--#{$prefix}primary) !important;
    }
  }
}

/* -------------------------------------------------------------------------- */
/*                                  LMS                                       */
/* -------------------------------------------------------------------------- */
.bar-indicator-gradient {
  background: linear-gradient(to right, rgba($primary, 0.1), var(--#{$prefix}primary));
  height: 0.25rem;
  width: 100%;
  border-radius: 1rem;
}

.echart-avg-enrollment-rate {
  min-height: 18.75rem;
}

.d3-trending-keywords {
  height: 25rem;
}

.lms-half-doughnut {
  max-width: 10.625rem;
}

.echart-marketing-expenses {
  min-height: 18.75rem;
}

.marketing-exp-circle {
  width: 6.25rem;
  height: 6.25rem;
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.075);
}

.echart-weekly-goals-lms {
  height: 15.625rem;
}

.echart-bar-course-enrollments {
  min-height: 15.625rem;
}

.top-courses-tab {
  .nav-link {
    color: var(--falcon-gray-400) !important;

    &:not(.active):hover {
      border-color: transparent !important;
    }

    &.active {
      border-bottom: 2px solid var(--#{$prefix}primary);
      background-color: var(--#{$prefix}emphasis-bg);
      color: var(--#{$prefix}primary) !important;
    }
  }
}