/* -------------------------------------------------------------------------- */
/*                                    Badge                                   */
/* -------------------------------------------------------------------------- */

@mixin badge-subtle-variant($color) {
  color: var(--#{$prefix}#{$color}-text-emphasis);
  background-color: var(--#{$prefix}#{$color}-bg-subtle);
  &[href] {
    @include hover-focus {
      text-decoration: none;
      color: var(--#{$prefix}#{$color}-text-emphasis);
      background-color: var(--#{$prefix}#{$color}-bg-subtle);
    }
  }
}

@each $color, $value in $theme-colors {
  .badge-subtle-#{$color} {
    @include badge-subtle-variant($color)
  }
}
