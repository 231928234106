.select2 {
  width: 100% !important; 
}
.select2-container {  
  .select2-selection,
  .select2-selection__rendered,
  .select2-dropdown, 
  .select2-search__field {
    background-color: $input-bg !important;
    color: $input-color !important;
    border-color: $input-border-color !important;
  }
  .select2-selection__choice {
    padding: map_get($spacers, 1) map_get($spacers, 2) !important;
    font-size: map-get($font-sizes, 10) !important;
    background-color: var(--#{$prefix}gray-200);
    color: var(--#{$prefix}body-color) !important;
    border: none !important;

    .select2-selection__choice__remove {
      background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23666a6d'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.5rem auto no-repeat !important;
      &:hover {
        background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2385878a'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.5rem auto no-repeat !important;
      }
    }
  }
  .select2-results__option.select2-results__option--highlighted:not(.select2-results__option--selected) {
    background-color: $dropdown-link-hover-bg !important;
    color: $input-color !important;
  }
}

html[data-bs-theme="dark"] {
  .select2-results__option.select2-results__option--highlighted:not(.select2-results__option--selected) {
    background-color: $dropdown-dark-link-hover-bg !important;
    color: $input-color !important;
  }
}